import(/* webpackMode: "eager", webpackExports: ["ThemeRegistry"] */ "/builds/empiriecom/core/frontend/turborepo/apps/shopping/src/components/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/node_modules/next/font/local/target.css?{\"path\":\"../../packages/themes/src/jelmolich/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Manrope-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/Manrope-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/Manrope-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-primary\",\"preload\":false}],\"variableName\":\"primary\"}");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/node_modules/next/font/local/target.css?{\"path\":\"../../packages/themes/src/jelmolich/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/kalam-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-secondary\",\"preload\":false}],\"variableName\":\"secondary\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LoaderSwrConfig"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/LoaderSwrConfig/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicFooter"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/Footer/Footer.dynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Usp"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/Usp/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GqlProviderWithGraphcache"] */ "/builds/empiriecom/core/frontend/turborepo/packages/gql/src/GqlProviderWithGraphcache.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DefaultHeaderFallback"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/Header/DefaultHeader/loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwrConfigClientWrapper"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/Header/SwrConfigClientWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkedLogo"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/Header/Toolbar/LinkedLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LazyRender"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/LazyRender/LazyRender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicRedirectOverlay"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/RedirectOverlay/RedirectOverlay.dynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestPanelLoader"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/TestPanel/TestPanelLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Snackbar"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/Notification/Snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/Notification/SnackbarContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/ConfigProvider/ConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/CookieProvider/CookieProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JotaiProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/JotaiProvider/JotaiProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/LanguageProvider/LanguageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MediaProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/MediaProvider/MediaProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/NotificationProvider/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageTransitionProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/PageTransitionProvider/PageTransitionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/SessionProvider/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageProgressBar"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/ui/PageProgressBar/PageProgressBar.tsx");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/packages/themes/dist/default-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["PageviewProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/tracking/src/components/PageviewProvider/PageviewProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Soasta"] */ "/builds/empiriecom/core/frontend/turborepo/packages/tracking/src/components/Soasta/Soasta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/builds/empiriecom/core/frontend/turborepo/packages/tracking/src/components/TrackingProvider/TrackingProvider.tsx");
